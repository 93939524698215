/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Homepage } from "../../../components/homepage"

export const pageQuery = graphql`
  query gdansk {
    file(relativePath: { eq: "parking-lotnisko-gdansk-front.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
      }
    }
    posts: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order_parking] }
      filter: { frontmatter: { template: { eq: "parking-post" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            slug
            title
            isParkVia
            doNotShowOnMainPage
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`

const frontmatter = {
  template: "index-page",
  slug: "/",
  title: "Parking Lotnisko Gdańsk Rębiechowo",
  tagline: "Gdańsk Rębiechowo Polska",
  featuredImage: "/assets/parking-lotnisko-gdansk-front.jpeg",
  cta: {
    ctaText: "Wyszukaj parking",
    ctaLink: "#search",
  },
}

const HomePage = ({ data }) => (
  <Homepage
    frontmatter={frontmatter}
    data={data}
    descriptionComponent={
      <p>
        <b>Adres:</b> ul. Juliusza Słowackiego 200, 80-298 Gdańsk Międzynarodowy
        port lotniczy położony w gdańskiej dzielnicy Matarnia, pierwotnie
        nazywany Gdańsk Rębiechowo; położony 10 km od centrum Gdańska i Sopotu
        oraz 23 km od centrum Gdyni. Port położony jest nieopodal trójmiejskiej
        obwodnicy
      </p>
    }
  >
    <h2>
      <strong>
        Parkingi przy lotnisku Gdańsk - jak znaleźć najlepsze miejsce do
        parkowania?
      </strong>
    </h2>
    <p>
      Szukasz bezpiecznego i wygodnego miejsca do pozostawienia swojego
      samochodu na czas podróży z lotniska Gdańsk? Przeczytaj nasz przewodnik,
      aby poznać najlepsze parkingi przy lotnisku Gdańsk oraz porady dotyczące
      wyboru odpowiedniego parkingu.
    </p>
    <p>
      Podróżowanie samolotem może być stresujące, a do tego dochodzi jeszcze
      kwestia parkowania samochodu na czas nieobecności. Znalezienie
      bezpiecznego i wygodnego parkingu przy lotnisku Gdańsk może być nie lada
      wyzwaniem, zwłaszcza dla osób, które nie znają zbyt dobrze miasta i
      okolic.
    </p>
    <h2>
      <strong>Parkingi przy lotnisku Gdańsk - udogodnienia</strong>
    </h2>
    <ul>
      <li>
        <p>24-godzinna ochrona i monitoring,</p>
      </li>
      <li>
        <p>ubezpieczenie samochodu,</p>
      </li>
      <li>
        <p>bezpłatny transfer na lotnisko oraz z powrotem,</p>
      </li>
      <li>
        <p>możliwość dokonywania rezerwacji online.</p>
      </li>
      <li>
        <p>możliwość odwoływania rezerwacji</p>
      </li>
      <li>
        <p>ochrona 24/7h</p>
      </li>
      <li>
        <p>monitoring</p>
      </li>
      <li>
        <p>ogrodzenie</p>
      </li>
    </ul>
    <h2>
      <strong>Najczęściej zadawane pytania</strong>
    </h2>
    <h3>
      <strong>
        Czy parkingi przy lotnisku Gdańsk oferują ubezpieczenie dla samochodów?
      </strong>
    </h3>
    <p>
      Tak, większość parkingów przy lotnisku Gdańsk oferuje ubezpieczenie dla
      samochodów. Przed dokonaniem wyboru parkingu, warto sprawdzić, czy oferuje
      on ubezpieczenie oraz jakie są warunki ubezpieczenia.
    </p>
    <h3>
      <strong>
        Czy parkingi przy lotnisku Gdańsk są monitorowane i zabezpieczone?
      </strong>
    </h3>
    <p>
      Tak, większość parkingów przy lotnisku Gdańsk jest monitorowana i
      zabezpieczona przez ochronę. Warto jednak sprawdzić, jakie dokładnie są
      środki zabezpieczenia, aby mieć pewność, że Twój samochód jest bezpieczny.
    </p>
    <h3>
      <strong>
        Czy muszę dokonywać rezerwacji na parking przed przyjazdem?
      </strong>
    </h3>
    <p>
      Nie, nie musisz dokonywać rezerwacji przed przyjazdem na parking, ale
      zaleca się to, aby uniknąć problemów z brakiem wolnych miejsc
      parkingowych. Większość parkingów przy lotnisku Gdańsk umożliwia
      dokonywanie rezerwacji online.
    </p>
    <h2>
      <strong>Podsumowanie</strong>
    </h2>
    <p>
      Znalezienie dobrego miejsca do parkowania przy lotnisku Gdańsk może być
      trudne, zwłaszcza dla osób, które nie znają okolicy. Warto zwrócić uwagę
      na takie kwestie jak ochrona i monitoring parkingu, transfer na lotnisko,
      ubezpieczenie samochodu oraz ceny, aby wybrać najlepsze miejsce do
      pozostawienia swojego pojazdu na czas podróży. Wskazane jest również, aby
      dokonać rezerwacji online, aby zaoszczędzić czas i uniknąć problemów z
      brakiem wolnych miejsc parkingowych. Dzięki temu przylecieć na lotnisko
      Gdańsk nie będzie już stanowiło problemu, a Ty będziesz mógł skupić się na
      swojej podróży.
    </p>
  </Homepage>
)

export default HomePage
